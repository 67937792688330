/* eslint react/prop-types: 0 */

import React from "react";
import {graphql} from "gatsby";
import Layout from '../components/layout';
import SEO from "../components/seo";
import Img from "gatsby-image";

const SinglePost = ({data}) => {
    const {markdownRemark} = data;
    const {title, date, author, image} = markdownRemark.frontmatter;
    const {html} = markdownRemark;

    return (
        <Layout>
            <SEO title={title} bodyClasses="single"/>
            <article className="single--mostre">

                <div className="article__section article__header article__header--single">

                    <div className="article__cover article__cover--single">
                        {/* <div className="responsive_placeholder responsive_placeholder--loaded" style={{ paddingBottom: `66.7572%` }}></div> */}
                        <Img fluid={image.childImageSharp.fluid}/>
                        <div className="article__caption article__caption--single">
                            <div className="">{title}
                            </div>
                        </div>
                    </div>

                    <div className="article__intro article__intro--single">

                        <h6 className="article__label article__label--single">Mostra</h6>
                        <h1 className="article__title article__title--single">{title}</h1>
                        <h2 className="article__subtitle article__subtitle--single">{author}</h2>
                        <h2 className="article__subtitle article__subtitle--single">{date}</h2>

                        <div className="article__info article__info--single">
                            <h4>Lorem Ipsum</h4>
                            <h4><p>&nbsp;</p>
                                <p><strong>Opening 07.06.2019</strong><br/>
                                    <strong>ore 19.00-22.00</strong></p>
                                <p>&nbsp;</p>
                            </h4>
                        </div>


                        <div className="article__tickets">
                            <a href="/" className="input input--button input--link">Orari e biglietti</a>
                        </div>

                        <div className="article__sharing article__sharing--single">
                            <div className="sharing__wrapper">
                                <div className="sharing__title sharing__trigger">
                                    Condividi
                                </div>
                                <nav className="sharing__list">
                                    <ul>
                                        <li>
                                            <a className="sharing__link"
                                               href="https://facebook.com/sharer/sharer.php?u=" target="_blank"
                                               rel="noopener noreferrer"
                                               aria-label="Share on Facebook">Facebook</a>
                                        </li>
                                        <li>
                                            <a className="sharing__link"
                                               href="https://twitter.com/intent/tweet/?text=Pier%20Paolo%20Calzolari.Madre%20Napoli%20-%20museo%20d%26%23039%3Barte%20contemporanea%20Donnaregina&amp;url="
                                               target="_blank" rel="noopener noreferrer"
                                               aria-label="Share on Twitter">Twitter</a>
                                        </li>
                                        <li>
                                            <a className="sharing__link"
                                               href="mailto:?subject=Pier%20Paolo%20Calzolari.Madre%20Napoli%20-%20museo%20d%26%23039%3Barte%20contemporanea%20Donnaregina&amp;body="
                                               target="_self" aria-label="Share by E-Mail">E-Mail</a>
                                        </li>
                                    </ul>
                                </nav>

                            </div>
                        </div>
                        <div className="article__instagram article__instagram--single">
                            Segui la mostra su <a
                            href="/">Instagram</a>
                        </div>


                    </div>

                </div>

                <div
                    className="article__section article__content article__content--single"
                    dangerouslySetInnerHTML={{__html: html}}
                />
                <hr/>

            </article>

        </Layout>
    );
};

export default SinglePost

export const query = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(fields: {slug: {eq: $path}}) {
            html
            fields {
#                tagSlugs {
#                    tag
#                    link
#                }
                slug
            }
            frontmatter {
                title
                date(formatString: "DD/MM/YYYY")
#                path
                author
                image {
                    childImageSharp{
                        fluid(maxWidth: 900){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;